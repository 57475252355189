import * as React from 'react';
import { Link as GastbyLink } from 'gatsby';
import { fallDown as Menu } from 'react-burger-menu';
import { useTheme, Button, Text, Box, Link, Flex } from '@chakra-ui/core';
import { FaInstagram } from 'react-icons/fa';
import { useNavigationContext } from './Navigation.Provider';

const routes = [
  {
    path: '#how-it-works',
    label: 'About',
  },
  {
    path: '#faq',
    label: 'Faq',
  },
];

export const MobileSidebar = () => {
  const theme = useTheme();

  const styles = {
    bmMenuWrap: {
      position: 'fixed',
      marginTop: '0px',
      zIndex: '5',
    },
    bmMenu: {
      fontSize: '1.15em',
      backgroundColor: theme.colors.blue['500'],
    },
    bmItemList: {
      padding: '10px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      flexDirection: 'column',
    },
    bmItem: {
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
      height: 75,
    },
    bmOverlay: {
      background: 'rgba(0, 0, 0, 0.3)',
      marginTop: '0px',
      zIndex: '4',
    },
  };

  const { isSidebarOpen, setIsSidebarOpen } = useNavigationContext();

  const handleLinkClick = (e: React.SyntheticEvent) => {
    e.preventDefault();

    setIsSidebarOpen(false);
  };

  return (
    <Menu
      width="100vw"
      styles={styles}
      disableAutoFocus
      right
      isOpen={isSidebarOpen}
      customBurgerIcon={false}
      customCrossIcon={false}
      onStateChange={state => {
        setIsSidebarOpen(state.isOpen);
      }}
    >
      {routes.map(route => (
        <Button
          p="0"
          border="none"
          bg="transparent"
          onClick={handleLinkClick}
          _hover={{ bg: 'transparent' }}
          key={route.label}
        >
          <GastbyLink to={route.path}>
            <Text color="yellow.100" fontFamily="heading">
              {route.label}
            </Text>
          </GastbyLink>
        </Button>
      ))}
      <Button
        onClick={() => {
          window.location.href = 'https://app.mamasgotchu.com/login';
        }}
        color="pink.400"
        variantColor="yellow"
        bg="yellow.100"
        size="md"
        display={['none', 'none', 'none', 'initial']}
        rounded="lg"
        shadow="md"
        _hover={{
          shadow: 'xl',
        }}
        _active={{
          shadow: 'sm',
        }}
        fontFamily="heading"
        mx={[4, 8, 12]}
        mb={4}
        fontSize={['sm', 'md', 'xl']}
      >
        Log in
      </Button>
      <Button
        onClick={() => {
          window.location.href = 'https://app.mamasgotchu.com/welcome';
        }}
        color="yellow.100"
        variantColor="pink"
        bg="pink.500"
        size="md"
        display={['none', 'none', 'none', 'initial']}
        rounded="lg"
        shadow="md"
        _hover={{
          shadow: 'xl',
        }}
        _active={{
          shadow: 'sm',
        }}
        fontFamily="heading"
        mx={[4, 8, 12]}
        mb={4}
        fontSize={['sm', 'md', 'xl']}
      >
        Sign up now
      </Button>
      <Button
        onClick={() => {
          window.location.href =
            'https://www.gofundme.com/f/help-feed-women-in-need?utm_source=customer&utm_medium=copy_link&utm_campaign=p_cf+share-flow-1';
        }}
        color="yellow.100"
        variantColor="pink"
        bg="blue.300"
        size="md"
        display={['none', 'none', 'none', 'initial']}
        rounded="lg"
        shadow="md"
        _hover={{
          shadow: 'xl',
        }}
        _active={{
          shadow: 'sm',
        }}
        fontFamily="heading"
        mx={[4, 8, 12]}
        fontSize={['sm', 'md', 'xl']}
      >
        Donate to ELAWC
      </Button>
      <Flex height="75px" alignItems={['center']} mt={4}>
        <Link href="https://www.instagram.com/drizzle.catering/">
          <Box as={FaInstagram} color="yellow.100" size={50} />
        </Link>
      </Flex>
    </Menu>
  );
};
