import * as React from 'react';
import { Link as GatsbyLink } from 'gatsby';
import { HamburgerButton } from 'react-hamburger-button';
import { Flex, Text, Box, Link, useTheme, Button } from '@chakra-ui/core';
import { FaInstagram } from 'react-icons/fa';
import { Logo } from './Logo';
import { useNavigationContext } from './Navigation.Provider';

export const NavLink: React.FC<any> = ({ path, ...props }) => (
  <GatsbyLink to={path}>
    <Text
      color="white"
      mx={[4, 8, 8]}
      fontFamily="heading"
      fontSize={['sm', 'md', '3xl']}
      {...props}
    />
  </GatsbyLink>
);

export const NavBar: React.FC<{}> = () => {
  const { toggleSidebar, isSidebarOpen } = useNavigationContext();
  const theme = useTheme();

  return (
    <Flex
      width={['100%']}
      justifyContent={['flex-start']}
      alignItems={['center']}
      py={5}
      position={isSidebarOpen ? 'fixed' : 'absolute'}
      zIndex={1}
      px={[4, 4, 4, 8]}
      transition="0.2s ease-in-out all"
      transform="none"
      bg="transparent"
    >
      <Logo mx={[0, 0, 0, 8]} />
      <Flex flex={1} justifyContent={['flex-start']} alignItems={['center']}>
        <NavLink
          display={['none', 'none', 'none', 'block']}
          path="#how-it-works"
        >
          About
        </NavLink>
        <NavLink display={['none', 'none', 'none', 'block']} path="#faq">
          Faq
        </NavLink>
        <Link
          href="https://instagram.com/mamasgotchu_"
          display={['none', 'none', 'none', 'initial']}
        >
          <Box as={FaInstagram} color="white" mx={[4, 8, 12]} size={50} />
        </Link>
      </Flex>
      <Flex justifyContent={['flex-start']} alignItems={['center']}>
        <Button
          onClick={() => {
            window.location.href = 'https://app.mamasgotchu.com/welcome';
          }}
          color="yellow.100"
          variantColor="pink"
          bg="pink.500"
          size="lg"
          display={['none', 'none', 'none', 'initial']}
          rounded="lg"
          shadow="md"
          fontFamily="heading"
          mx={[4]}
          fontSize={['sm', 'md', 'xl']}
        >
          Sign up now
        </Button>
        <Button
          onClick={() => {
            window.location.href = 'https://app.mamasgotchu.com/login';
          }}
          color="pink.400"
          variantColor="yellow"
          bg="yellow.100"
          size="lg"
          display={['none', 'none', 'none', 'initial']}
          rounded="lg"
          shadow="md"
          fontFamily="heading"
          mx={[4]}
          fontSize={['sm', 'md', 'xl']}
        >
          Log in
        </Button>
        <Button
          onClick={() => {
            window.location.href =
              'https://www.gofundme.com/f/help-feed-women-in-need?utm_source=customer&utm_medium=copy_link&utm_campaign=p_cf+share-flow-1';
          }}
          color="yellow.100"
          variantColor="blue"
          bg="blue.500"
          size="lg"
          display={['none', 'none', 'none', 'initial']}
          rounded="lg"
          shadow="md"
          fontFamily="heading"
          mx={[4]}
          fontSize={['sm', 'md', 'xl']}
        >
          Donate to ELAWC
        </Button>
      </Flex>
      <Box
        display={['block', 'block', 'block', 'none']}
        position="fixed"
        right={8}
        p={3}
        rounded="lg"
        shadow={isSidebarOpen ? 'none' : 'md'}
        bg="blue.500"
      >
        <HamburgerButton
          color={theme.colors.pink['300']}
          height={20}
          onClick={toggleSidebar}
          open={isSidebarOpen}
          strokeWidth={3}
          width={35}
        />
      </Box>
    </Flex>
  );
};
