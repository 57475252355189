import * as React from 'react';
import {
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Stack,
} from '@chakra-ui/core';

interface PivotModalProps {
  setHasSeenMessage?(bool: boolean): void;
  onClose(): void;
  isOpen: boolean;
}

export const PivotModal = ({
  setHasSeenMessage,
  onClose,
  isOpen,
}: PivotModalProps) => {
  const handleCloseClick = () => {
    if (setHasSeenMessage) {
      setHasSeenMessage(true);
    }
    onClose();
    if (typeof window !== 'undefined') {
      localStorage.setItem('modal-message-1', 'true');
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={handleCloseClick}>
      <ModalOverlay />
      <ModalContent
        bg="pink.400"
        rounded="md"
        shadow="md"
        border="3px solid"
        borderColor="white"
        maxWidth={['90%', '90%', '50%']}
      >
        <ModalHeader>UPDATE FROM MAMA’S KITCHEN</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing={4}>
            <Text>
              Isn&apos;t it funny what we get excited about these days? Not
              waiting hours in scorching heat in the hopes of stocking up on
              decent, yet inexpensive, snacks and wine at Trader Joe&apos;s sits
              high up on that list. Getting essentials can feel like a wild
              goose chase across various grocery stores, not to mention constant
              price fluctuations and food shortages.
            </Text>

            <Text>
              Like so many, we&apos;ve stocked up on pantry and freezer items to
              continue meal planning but variety is key to that stock up life
              because who wants to eat pasta salad for the third week in a row?
              Our mission at Mama&apos;s Gotchu has always been to inspire joy
              in the everyday meal and we&apos;re working on updating our
              product offerings to give you more flexibility and stock up
              options.
            </Text>

            <Text>
              Keep your snack times delish and good for you with our signature
              dips. Bring Mama&apos;s flavors to your own kitchen with the base
              sauce and spices from our most popular dishes! Our packaged snacks
              and meal components will come ready to use in sealed bags and easy
              storage. Occasionally, we will release limited edition seasonal
              items from local farmers, including ourselves!
            </Text>

            <Text>
              For now, we&apos;re switching to a limited menu available in
              Family Meal portions as we continue developing our packaged
              goodies. Order through the app this week! Thanks for your
              continued support. Mama loves y&apos;all!
              {` `}
              <span role="img" aria-label="smooch">
                😘
              </span>
            </Text>
          </Stack>
        </ModalBody>

        <ModalFooter>
          <Button
            variantColor="blue"
            bg="blue.400"
            mr={3}
            color="yellow.50"
            fontFamily="heading"
            onClick={handleCloseClick}
          >
            Gotcha!
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
