import * as React from 'react';
import { Image, ImageProps } from '@chakra-ui/core';
import logo from './assets/mamas-gotchu-logo.png';

export const Logo: React.FC<ImageProps> = props => {
  return (
    <Image
      bg="rgba(255,255,255,0.8)"
      rounded="50%"
      border="3px solid"
      shadow="md"
      borderColor="white"
      src={logo}
      width={[100, 100, 100, 150]}
      height={[100, 100, 100, 150]}
      {...props}
    />
  );
};
