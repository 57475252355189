import * as React from 'react';
import * as yup from 'yup';
import { useToast, Flex, Text, Input, Box, Link } from '@chakra-ui/core';
import addToMailchimp from 'gatsby-plugin-mailchimp';
import { FaInstagram } from 'react-icons/fa';

import {
  Form,
  Formik,
  Field,
  ErrorMessage,
  ErrorMessageProps,
  FieldProps,
  FormikValues,
} from 'formik';
import { Logo } from './Logo';
import { NavLink } from './NavBar';
import { Button } from './Button';

interface DrizzleErrorMessagePropsInterface extends ErrorMessageProps {
  color?: string;
}

export const DrizzleErrorMessage: React.FC<DrizzleErrorMessagePropsInterface> = ({
  name,
  color = 'pink',
  ...props
}) => (
  <ErrorMessage name={name}>
    {msg => (
      <Text fontSize="xs" fontFamily="body" color={color} {...props}>
        {msg}
      </Text>
    )}
  </ErrorMessage>
);

const schema = yup.object().shape({
  email: yup
    .string()
    .email()
    .required('Required'),
});

const SuccessToast = () => (
  <Box p={5} bg="turquoise">
    <Text fontFamily="body" color="white">
      Thanks for subscribing! We’re thrilled to have you as a part of the
      {/* eslint-disable-next-line */}
      Drizzle family.{' '}
      <span role="img" aria-label="sad-face">
        🥰
      </span>
    </Text>
  </Box>
);

const ErrorToast = () => (
  <Box p={5} bg="error">
    <Text fontFamily="body" color="white">
      {/* eslint-disable-next-line */}
      There was an issue registering your email{' '}
      <span role="img" aria-label="sad-face">
        🙍🏽
      </span>
    </Text>
  </Box>
);

export const Footer: React.FC<{}> = () => {
  const toast = useToast();
  const handleSubmitNewsletterSignUp = async (
    values: FormikValues,
    { setSubmitting, resetForm },
  ) => {
    try {
      const response = await addToMailchimp(values.email);
      if (response.result === 'success') {
        toast({
          render: SuccessToast,
        });
      }

      if (response.result === 'error') {
        toast({
          render: ErrorToast,
        });
      }
    } catch (e) {
      console.log(e);
    }

    resetForm();
    setSubmitting(false);
  };

  return (
    <Flex
      width={['100%']}
      bg="blue.500"
      py={[8, 8, 8]}
      px={[4, 8, 8, 20]}
      flexDirection={['column']}
      alignItems={['center', 'center', 'center', 'flex-start']}
      borderTop="3px solid"
      borderColor="white"
    >
      <Logo />
      <Flex
        width={['100%']}
        justifyContent={[
          'flex-start',
          'flex-start',
          'flex-start',
          'space-between',
        ]}
        mt={[4]}
        flexDirection={['column', 'column', 'column', 'row']}
      >
        <Flex
          alignItems={['center']}
          display={['none', 'none', 'none', 'flex']}
        >
          <NavLink color="white" mr={[1, 1, 1, 3]} ml={[0, 0, 0, 0]}>
            About
          </NavLink>
          <NavLink mx={[1, 3]} color="white">
            FAQ
          </NavLink>
          <Link href="https://www.instagram.com/drizzle.catering/">
            <Box as={FaInstagram} ml={[1, 3]} size={30} />
          </Link>
        </Flex>
        <Flex
          alignItems={['center', 'center', 'center', 'flex-end']}
          justifyContent={['center']}
          flexDirection={['column']}
        >
          <Flex
            alignItems={['center']}
            justifyContent={[
              'flex-start',
              'flex-start',
              'flex-start',
              'center',
            ]}
            flexDirection={['column', 'column', 'column', 'row']}
          >
            <Text
              whiteSpace="nowrap"
              fontFamily="heading"
              color="white"
              mr={[0, 0, 0, 3]}
              mt={[6, 6, 6, 0]}
              fontSize={['sm', 'md', 'lg', 'xl']}
              mb={[1, 1, 1, 0]}
            >
              Sign up for our mailing list
            </Text>
            <Formik
              initialValues={{
                email: '',
              }}
              validationSchema={schema}
              onSubmit={handleSubmitNewsletterSignUp}
            >
              {({ handleSubmit, isValid, isSubmitting }) => (
                <Form onSubmit={handleSubmit}>
                  <Flex>
                    <Flex
                      flexDirection={['column']}
                      mb={[5, 5, 5, 0]}
                      alignItems={['center']}
                    >
                      <Field
                        name="email"
                        render={({ form, field }: FieldProps) => (
                          <Input bg="blue.300" {...field} />
                        )}
                      />
                      <DrizzleErrorMessage name="email" />
                    </Flex>
                    <Button
                      fontSize="3xl"
                      ml={2}
                      type="submit"
                      textAlign="center"
                      bg="pink"
                      px={3}
                      border="2px dashed"
                      rounded="lg"
                      borderColor="blue.200"
                      isDisabled={!isValid || isSubmitting}
                    >
                      <span role="img" aria-label="submit">
                        🍱
                      </span>
                    </Button>
                  </Flex>
                </Form>
              )}
            </Formik>
            <Link
              href="https://instagram.com/mamasgotchu_"
              display={['initial', 'initial', 'initial', 'none']}
            >
              <Box as={FaInstagram} ml={[1, 3]} />
            </Link>
          </Flex>
          <Text color="white" mt={3} fontFamily="body">
            © 2020 Drizzle
          </Text>
          <Flex height="100%" alignItems="flex-end">
            <Link href="http://www.baileyrobb.com/" target="_blank">
              Photo Credit Bailey Robb
            </Link>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};
