import React from 'react';
import { Global, css } from '@emotion/core';
import { ParallaxProvider } from 'react-scroll-parallax';
import { ThemeProvider, CSSReset, Flex, useDisclosure } from '@chakra-ui/core';
import { ApolloProvider } from './ApolloProvider';
import { theme } from '../theme';
import { NavBar } from './NavBar';
import { Footer } from './Footer';
import { NavigationProvider } from './Navigation.Provider';
import { MobileSidebar } from './MobileNav';
import { PivotModal } from './PivotModal';

const Layout: React.FC = ({ children }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [hasSeenMessage, setHasSeenMessage] = React.useState(
    typeof window !== 'undefined'
      ? Boolean(localStorage.getItem('modal-message-1'))
      : true,
  );

  React.useEffect(() => {
    if (!hasSeenMessage) {
      onOpen();
    }
  }, [hasSeenMessage]);

  return (
    <ThemeProvider theme={theme}>
      <Global
        styles={css`
          @font-face {
            font-family: Brandish;
            font-weight: 400;
            font-style: normal;
            src: url('/fonts/brandishregular.ttf') format('truetype');
            font-display: swap;
          }
        `}
      />
      <PivotModal
        setHasSeenMessage={setHasSeenMessage}
        onClose={onClose}
        isOpen={isOpen}
      />
      <ApolloProvider>
        <ParallaxProvider>
          <NavigationProvider>
            <CSSReset />
            <Flex w="100%" bg="red" flexDirection="column">
              <MobileSidebar />
              <NavBar />
              <Flex flex={1} flexDirection={['column']}>
                {children}
              </Flex>
              <Footer />
            </Flex>
          </NavigationProvider>
        </ParallaxProvider>
      </ApolloProvider>
    </ThemeProvider>
  );
};

export default Layout;
